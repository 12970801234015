export function SubscriptionModal({ isOpen, onClose, subscriptionRequired, pageMessage }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center min-w-fit">
            <div className="relative mx-auto p-8 border border-gray-300 w-1/3 shadow-2xl rounded-lg bg-white">
                <div className="text-center">
                    <h3 className="text-2xl font-bold text-gray-900">{subscriptionRequired} Subscription Required</h3>
                    <div className="mt-4 px-6 py-4">
                        <p className="text-sm text-gray-600 mb-4">
                            {pageMessage}
                        </p>
                        <div className="border-t border-gray-200 pt-4">
                            <ul className="list-disc list-inside pl-5 text-left text-gray-700">
                                <li className="mb-2">Transcripts</li>
                                <li className={"mb-2"}>AI Language Model Querying</li>
                                <li className="mb-2">Table look up</li>
                                <li className="mb-2">Full text search</li>
                                <li className="mb-2">And grandfathered into all future features</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            id="ok-btn"
                            className="w-full py-3 bg-neutral-900 text-white text-base font-semibold rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                            onClick={onClose}
                        >
                            Subscription Options
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );
}