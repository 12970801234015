import {Globe, Lock} from "lucide-react";
import FeedbackButtons from "./FeedbackButtons";
import React, {useState} from "react";
import {API_URL} from "../../config";


const AnimatedEllipsis = () => (
    <span className="inline-flex">
    <span className="animate-[pulse_1s_ease-in-out_infinite]">.</span>
    <span className="animate-[pulse_1s_ease-in-out_0.333s_infinite]">.</span>
    <span className="animate-[pulse_1s_ease-in-out_0.667s_infinite]">.</span>
  </span>
);

const CompanyArtifact = ({
                             currentUser,
                             queryRequested,
                             streamId,
                             createdDate,
                             responseRef,
                             isStreaming,
                             displayData,
                             showFeedback,
                             onDelete}) => {

    const [isPublic, setIsPublic] = useState(false);

    const handleFeedbackGiven = () => {
        console.log('Feedback given');
    };

    const togglePublicPrivate = () => {
        sendPublicPrivateChange(!isPublic).then(r => {
            console.log("Successfully changed visibility")
        })
        setIsPublic(!isPublic);
    };


    const sendPublicPrivateChange = async (isPublic) => {
        try {
            const res = await fetch(`${API_URL}/api/company/query/llm/public/${streamId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${currentUser.token}`
                },
                body: JSON.stringify({
                    id: streamId,
                    isPublic,
                }),
            });

            if (res.ok) {
                console.log("Artifact visibility changed")
            } else {
                console.error('Failed to send feedback');
            }
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    };


    const formatDateV2 = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1; // getMonth() returns 0-11
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${month}/${day}/${year} - ${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };

    return (
        <div className="mt-4 p-6 mx-2 md:mx-auto bg-gray-100 rounded-md shadow-inner relative">
            <div className="flex justify-between items-center mb-2">
                {streamId ? (
                    <div className="flex flex-col mb-1">
                        <h3 className="text-md text-gray-400">{formatDateV2(createdDate)}</h3>
                        <p className="text-sm text-gray-400 italic">id: {streamId.slice(0, 10)}</p>
                    </div>
                ) : (
                    <h3 className="text-lg font-semibold">Response:</h3>
                )}
                <button
                    onClick={togglePublicPrivate}
                    className="text-gray-500 hover:text-gray-700"
                    aria-label={togglePublicPrivate ? "Set to private" : "Set to public"}
                >
                    {isPublic ? <Globe size={18} /> : <Lock size={18} />}
                </button>
            </div>
            <div ref={responseRef}>
                {isStreaming && !displayData ? (
                    <p className="text-gray-600 flex items-center">
                        Querying best LLM model
                        <span className="ml-2">
                            <AnimatedEllipsis />
                        </span>
                    </p>
                ) : (
                    <div
                        className="text-gray-800 text-sm leading-relaxed font-mono"
                        dangerouslySetInnerHTML={{ __html: displayData }}
                    />
                )}
            </div>
            {showFeedback && displayData && (
                <>
                <FeedbackButtons
                    response={displayData}
                    user={currentUser}
                    onFeedbackGiven={handleFeedbackGiven}
                    streamId={streamId}
                    onDelete={onDelete}
                />
                <div className={"text-gray-600 text-sm leading-relaxed font-mono py-2 px-3 bg-gray-200 rounded mt-2"}>User query: {queryRequested}</div>
                </>
            )}
        </div>
    );
}

export default CompanyArtifact;