// Company.js
import { API_URL } from '../config.js';
import NavBarSmall from "./NavBarSmall";
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import {useNavigate, useParams} from 'react-router-dom';
import FilingCategory from "./FilingCatagory";
import FooterSmall from "./FooterSmall";
import {useAuth} from "../firebase/AuthContext";
import SimilarCompanies from "./SimilarCompanies/SimilarCompaniesV3";
import {arrayToStringWithCommasAndLengthLimitIntelligent} from "../values"
import CompanySearchBars from "./CompanySearchBars";
import CompanyDescription from "./LLMQuery/CompanyDescription";
import TransactionsTableTGGroup from "./InsiderTransactions/InsidersTGGroup";
import Transcripts from "./Transcript/TranscriptPageV2";
import {CompanyQueryInputV1} from "./LLMQuery/CompanyQueryV3";


const BetaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 14" width="30" height="14" className="ml-1 inline-block">
        <rect x="0" y="0" width="30" height="14" rx="7" fill="currentColor" opacity="0.2"/>
        <text x="15" y="10" font-family="Arial, sans-serif" font-size="8" font-weight="bold" fill="currentColor" text-anchor="middle">BETA</text>
    </svg>
);

const TabComponent = ({ activeTab, setActiveTab }) => {
    const tabs = ['Filings', 'Transcripts', 'Insider', "LLM Query"];

    return (
        <div className="flex border-b border-gray-200">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`py-1 px-4 font-medium flex items-center ${
                        activeTab === tab
                            ? 'border-b-2 border-neutral-600 text-neutral-900 bg-company-data-background'
                            : 'text-gray-500 hover:text-gray-700'
                    }`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                    {tab === "LLM Query" && <BetaIcon />}
                </button>
            ))}
        </div>
    );
};

function Company() {
    const [activeTab, setActiveTab] = useState('Filings');
    const [companyData, setCompanyData] = useState(null);
    const [similarCompanies, setSimilarCompanies] = useState(null);
    const { ticker } = useParams();
    const [filingsData, setFilingsData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser, refreshToken } = useAuth();
    const navigate = useNavigate();
    const [subscriptionTier, setSubscriptionTier] = useState("free")

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Filings':
                return  <div className="grid grid-cols-1 md:grid-cols-2 border md:p-3 lg:p-3 sm:p-0 bg-company-data-background">
                    {renderFilingCategories()}
                </div>
            case 'Transcripts':
                return <div className={"w-full md:p-3 lg:p-3 sm:p-0 bg-company-data-background"}>
                        <Transcripts ticker={ticker} currentUser={currentUser} />
                        </div>;
            case 'Insider':
                return <div className={"w-full md:p-3 lg:p-3 sm:p-0 bg-company-data-background"}>
                    <div className="bg-gray-200">
                        <h2 className="text-lg font-bold lg:px-4 pt-1 px-2">Insider Transactions - Grouped by filing</h2>
                        <TransactionsTableTGGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                </div>;
            case 'LLM Query':
                return <div className={"w-full md:px-3 lg:px-3 sm:p-0 bg-company-data-background"}>
                    <div className="bg-gray-200 py:0 p-1 lg:p-3 lg:py-0 ">
                        <CompanyQueryInputV1 subscriptionTier={subscriptionTier} companyID={companyData.cik} currentUser={currentUser} refreshToken={refreshToken} />
                    </div>
                </div>;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (companyData) {
            document.title = companyData.name + " | Modeledge";
        }
    }, [companyData]);

    const renderFilingCategories = () => {
        if (filingsData && typeof filingsData === 'object' && Object.keys(filingsData).length > 0) {
            return (
                <>
                    <FilingCategory key={`News_${ticker}_${filingsData['News']?.length}`} title="News" filings={filingsData['News'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Financial_${ticker}_${filingsData['Financial']?.length}`} title="Financial" filings={filingsData['Financial'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Prospectus_${ticker}_${filingsData['Prospectus']?.length}`} title="Prospectus" filings={filingsData['Prospectus'] || []} ticker={ticker} currentUser={currentUser}/>
                    <FilingCategory key={`Proxies_${ticker}_${filingsData['Proxies']?.length}`} title="Proxies" filings={filingsData['Proxies'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Ownership_${ticker}_${filingsData['Ownership']?.length}`} title="Ownership" filings={filingsData['Ownership'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`SEC_Correspondence_${ticker}_${filingsData['SEC Correspondence']?.length}`} title="SEC Correspondence" filings={filingsData['SEC Correspondence'] || []} ticker={ticker} currentUser={currentUser}/>
                </>
            );
        } else {
            return (
                <>
                    <>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">News</h2>
                        </div>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">Financial</h2>
                        </div>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">Prospectus</h2>
                        </div>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">Proxies</h2>
                        </div>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">Ownership</h2>
                        </div>
                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-lg font-bold mb-2">SEC Letters</h2>
                        </div>
                    </>
                </>

        );
            }
    };

    const handleFollowClick = () => {
        if (currentUser && !isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/add/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else if (currentUser && isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/remove/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else {
            navigate('/login')
        }
    };

    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};
        axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers })
            .then(response => {
                setIsFollowing(response.data.isFollowing)
                setCompanyData(response.data.company);
                setSimilarCompanies(response.data.similar);
                setSubscriptionTier(response.data.subscriptionTier);
                console.log("User with subscription: " + subscriptionTier)
            })
            .catch(error => {
                console.error('Error fetching company data:', error);
            });

        axiosInstance.get(`${API_URL}/api/company/filings/${ticker}`, { headers })
            .then(response => {
                setFilingsData(response.data.filings);
            })
            .catch(error => {
                console.error('Error fetching filings data:', error);
            });
    }, [ticker, currentUser, subscriptionTier]);

    useEffect(() => {
        //
    }, [isFollowing]);

    if (!companyData) {
        return (
            <>
                <div className="bg-code-light-black bg-center min-h-screen">
                <NavBarSmall />
                <div className="bg-filing-menu-background bg-center h-screen">

                </div>
            </div>
            </>)
    }




    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="lg:w-4/5 md:w-4/5 mx-auto">
                <div className="md:px-4 lg:px-4 p-1 sm:p-1">
                    <div className="container mx-auto pt-4 pb-2 lg:mt-2 ms:mt-1 px-1 md:px-0">
                        <div>
                            {
                                companyData.tickers ?
                            <h1 className="text-3xl text-filing-menu-text-color font-bold inline">{companyData.name} ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})</h1>
                                    :
                                    <h1 className="text-3xl text-filing-menu-text-color font-bold inline">{companyData.name}</h1>

                            }
                            <button
                                className={`ml-4 p-1 rounded text-xs font-semibold ${isFollowing ? 'bg-company-button text-bg-gray-100' : 'bg-gray-500 text-white'} text-white`}
                                onClick={handleFollowClick}
                            >
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                          </div>

                            <p className="text-sm text-filing-menu-text-light-color">{companyData.businessCity}, {companyData.businessState}</p>

                    </div>
                    <CompanyDescription ticker={ticker}/>
                    <SimilarCompanies ticker={ticker} similar={similarCompanies} />
                    <CompanySearchBars ticker={ticker} />


                </div>
                <div className="bg-cover bg-center min-h-screen">
                    <div className="container mx-auto md:p-2 lg:p-2 lg:pt-1 sm:p-1">
                        <TabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
                        <div className="w-full">
                            {renderTabContent()}
                        </div>

                    </div>
                </div>

            </div>
            {activeTab !== 'LLM Query' && <FooterSmall />}
        </div>
    )
}

export default Company;
