import React, { useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import CompanyArtifact from './CompanyArtifact';
import { API_URL } from '../../config';

const CompanyArtifactsList = ({ currentUser, companyID, onDelete }) => {
    const [artifacts, setArtifacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const itemsPerPage = 3;
    const [page, setPage] = useState(0);

    const handleDelete = async (deletedStreamId) => {
        // Call the parent's onDelete function
        await onDelete(deletedStreamId);

        // Remove the deleted artifact from the local state
        setArtifacts(prevArtifacts =>
            prevArtifacts.filter(artifact => artifact.artifactID !== deletedStreamId)
        );

        // If we've deleted an item and the list is now empty, try to fetch more
        if (artifacts.length === 1) {
            setPage(0);
            fetchArtifacts(0);
        }
    };

    const fetchArtifacts = useCallback(async (pageToFetch) => {
        if (!currentUser || !companyID) return;

        setLoading(true);
        const headers = { Authorization: `Bearer ${currentUser.token}` };
        const start = pageToFetch * itemsPerPage;
        const url = `${API_URL}/api/company/query/llm/artifacts/user/${companyID}?start=${start}&limit=${itemsPerPage}`;

        try {
            const response = await axios.get(url, { headers });
            if (Array.isArray(response.data)) {
                if (response.data.length === 0) {
                    setHasMore(false);
                } else {
                    setArtifacts((prevArtifacts) => [...prevArtifacts, ...response.data]);
                    setPage(pageToFetch + 1);
                    setHasMore(response.data.length === itemsPerPage);
                }
            } else {
                throw new Error('Invalid response format');
            }
        } catch (err) {
            console.error('Error fetching company artifacts:', err);
            setError('Failed to fetch artifacts. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, [currentUser, companyID, itemsPerPage]);

    useEffect(() => {
        setArtifacts([]);
        setPage(0);
        setHasMore(true);
        setError(null);
        fetchArtifacts(0);
    }, [currentUser, companyID, fetchArtifacts]);

    const handleLoadMore = () => {
        if (!loading && hasMore) {
            fetchArtifacts(page);
        }
    };

    if (error) {
        return <div className="text-center py-4 text-red-500">{error}</div>;
    }

    return (
        <div className="space-y-6">
            {artifacts.length > 0 ? (
                artifacts.map((artifact) => (
                    <CompanyArtifact
                        key={artifact.created}
                        currentUser={currentUser}
                        queryRequested={artifact.query}
                        streamId={artifact.artifactID}
                        createdDate={artifact.created}
                        responseRef={null}
                        isStreaming={false}
                        displayData={artifact.artifact}
                        showFeedback={true}
                        company={artifact.company}
                        onDelete={handleDelete}
                    />
                ))
            ) : (
                <div className="text-center"></div>
            )}

            {hasMore && (
                <div className="text-center mt-4">
                    <button
                        onClick={handleLoadMore}
                        disabled={loading}
                        className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                    >
                        {loading ? 'Loading...' : 'Load More'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default CompanyArtifactsList;