import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase/AuthContext";
import { API_URL } from "../../config";
import axiosInstance from "../../api";
import TranscriptMenu from "./TranscriptMenu";
import TranscriptIFrame from "./TranscriptIFrameV3";

const TranscriptDocument = () => {
    const { ticker, document_id } = useParams();
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768);
    const { currentUser } = useAuth();
    const [transcriptDetails, setTranscriptDetails] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (transcriptDetails) {
            document.title = transcriptDetails.event_title + " Transcript - " + transcriptDetails.company.name + " | Modeledge";
        }
    }, [transcriptDetails]);

    useEffect(() => {
        if (!document_id) return;

        const fetchData = async () => {
            try {
                const headers = currentUser
                    ? { Authorization: `Bearer ${currentUser.token}` }
                    : {};
                const url = `${API_URL}/api/transcripts/details/${document_id}`;
                const response = await axiosInstance.get(url, { headers });

                console.log(response.data);
                setTranscriptDetails(response.data);
                setError(null); // Clear any previous errors
            } catch (error) {
                console.error("Error fetching transcript data:", error);
                if (error.response && error.response.status === 404) {
                    setError("Transcript not found");
                } else {
                    setError("An error occurred while fetching the transcript");
                }
                setTranscriptDetails(null);
            }
        };
        fetchData();
    }, [document_id, currentUser]);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    if (!document_id) {
        return <div>No document ID provided</div>;
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <h2 className="text-2xl font-bold mb-4">{error}</h2>
                <button
                    className="px-4 py-2 bg-neutral-500 text-white rounded hover:bg-neutral-600"
                    onClick={() => navigate(-1)}
                >
                    Go Back
                </button>
            </div>
        );
    }

    if (!transcriptDetails) {
        return <div className="flex flex-col h-screen">
            <div className="flex flex-row flex-grow">
                <div className={`overflow-hidden transition-width duration-300 ease-in-out bg-filing-menu-background ${menuVisible ? 'w-80' : 'w-4 pl-1'}`}>
                    <TranscriptMenu
                        ticker={ticker}
                        type={""}
                        transcriptDetails={""}
                        menuVisible={menuVisible}
                        toggleMenu={toggleMenu}
                    />
                </div>
                <div className="flex-1 h-full">
                    <TranscriptIFrame
                        filingDoc={"/api/transcripts/" + document_id}
                        document_id={document_id}
                        exhibitNum={1}
                        menuVisible={menuVisible}
                        currentUser={currentUser}
                    />
                </div>
            </div>
        </div>
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex flex-row flex-grow">
                <div className={`overflow-hidden transition-width duration-300 ease-in-out bg-filing-menu-background ${menuVisible ? 'w-80' : 'md:w-4 w-6 pl-1'}`}>
                    <TranscriptMenu
                        ticker={ticker}
                        type={transcriptDetails.event_type}
                        transcriptDetails={transcriptDetails}
                        menuVisible={menuVisible}
                        toggleMenu={toggleMenu}
                    />
                </div>
                <div className="flex-1 h-full">
                    <TranscriptIFrame
                        filingDoc={"/api/transcripts/" + document_id}
                        document_id={document_id}
                        exhibitNum={1}
                        menuVisible={menuVisible}
                        currentUser={currentUser}
                    />
                </div>
            </div>
        </div>
    );
};

export default TranscriptDocument;