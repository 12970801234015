import React from "react";
import { Link } from 'react-router-dom';
import {formatDateObj, getRelativeDate, parseISODate} from '../../dates';

function shortenName(name) {
    const maxLength = 9;
    if (name.length > maxLength) {
        return name.substring(0, maxLength) + '...';
    }
    return name;
}
function FilingCardWithCompany({ filing, isLastItem = false, numItems = 10 }) {

    let cssClasses = `${isLastItem ? "border-b border-gray-500" : ""} ${filing.read ? "text-filing-is-read" : "text-black"}`;

    const relativeDate = getRelativeDate(new Date(filing.updated));
    let dateLabelClass = "text-vsm mt-1 py-0 px-2 rounded-md font-semibold";
    if (relativeDate) {
        dateLabelClass += ` ${relativeDate === "Today" ? "bg-green-600 hover:text-gray-200 bg-green-500 text-white hover:text-gray-200" : "bg-amber-400 text-gray-900 hover:text-gray-200"}`;
    }


    console.log("1: " + filing.updated)

    return (
        <div className={`bg-gray-50 shadow border-t border-gray-400 mb-0 ${cssClasses} py-0 m-0`}>
            <div className="flex flex-row justify-between items-center w-full">
                <Link to={`${filing.url}`} className="rounded-sm w-full flex">
                    <div className="flex items-center gap-2 text-xs py-2 md:py-1 pl-4 justify-between w-full hover:bg-slate-200">
                        <Link to={`/company/${filing.company.ticker ? filing.company.ticker[0] : filing.company.cik}`}>
                            <div className="font-roboto flex flex-col items-start justify-start mr-2 whitespace-nowrap hover:bg-slate-200 md:w-56 sm:w-16">
                                {/* Width set to w-58 for the company name on larger screens */}
                                <div className="hidden md:block w-56 truncate">
                                    {filing.company.name}
                                </div>
                                {/* Width set to w-16 for the ticker on smaller screens */}
                                <div className="w-12 md:hidden">
                                    {filing.company.ticker ? filing.company.ticker[0] :
                                        shortenName(filing.company.name)}
                                </div>
                            </div>
                        </Link>

                        <span className="font-roboto font-bold w-16 overflow-hidden flex-shrink-0">
                          {filing.filingType}
                        </span>
                        <span className="w-2 text-gray-500">|</span>
                        {/*  */}
                        <span className="font-roboto overflow-hidden whitespace-normal flex-grow-0 flex-shrink w-72 mr-4">
                          {filing.title}
                        </span>
                        <div className="font-roboto flex flex-col items-end justify-end w-32 mr-2">
                          <span className="text-xs text-gray-600">
                            {formatDateObj(parseISODate(filing.updated))}
                          </span>
                            {relativeDate && (
                                <span className={dateLabelClass}>{relativeDate}</span>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
            <div className="icon"></div>
        </div>
    );
}

export default FilingCardWithCompany;
