import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../firebase/AuthContext";
import SECFilingMenu from "../Components/SECFilingMenu";
import SECFilingIFrame from "../Components/SECFilingIFrame";
import { API_URL} from "../config";
import axiosInstance from '../api';

const SECFiling = () => {
    const { ticker, type, accession_num, exhibit_num } = useParams();
    const [filingData, setFilingData] = useState(null);
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768);
    //const [sequence, setSequence] = useState(0);
    const [seqIndex, setSeqIndex] = useState(0)
    const { currentUser } = useAuth();
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    useEffect(() => {
        if (filingData) {
            document.title = filingData.sec_filing.FilingType + " - " + filingData.company.name + " | Modeledge";
        }
    }, [filingData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = currentUser
                    ? { Authorization: `Bearer ${currentUser.token}` }
                    : {};
                const url = exhibit_num
                    ? `${API_URL}/api/filing/${accession_num}/${exhibit_num}`
                    : `${API_URL}/api/filing/${accession_num}`;
                const response = await axiosInstance.get(url, { headers });
                setFilingData(response.data);
            } catch (error) {
                console.error("Error fetching filing data:", error);
            }
        };

        fetchData();
    }, [accession_num, exhibit_num, currentUser]);


    useEffect(() => {
        if (filingData && filingData.exhibits && !exhibit_num) {
            if (filingData.sec_filing.FilingType === "8-K" || filingData.sec_filing.FilingType === "6-K") {
                var ex99Index = filingData.exhibits.findIndex(exhibit => exhibit.type === "EX-99.1");
                if (ex99Index === -1) {
                    ex99Index = filingData.exhibits.findIndex(exhibit => exhibit.type === "EX-99");
                }
                if (ex99Index === -1) {
                    ex99Index = filingData.exhibits.findIndex(exhibit => exhibit.type === "EX-99.01");
                }
                if (ex99Index !== -1) {
                    //setSequence(filingData.exhibits[ex99Index].sequence);
                    setSeqIndex(ex99Index);
                }
            }
        }

        if (exhibit_num && filingData && filingData.exhibits) {
            const index = filingData.exhibits.findIndex(exhibit => exhibit.sequence.toString() === exhibit_num);
            if (index !== -1) {
                setSeqIndex(index);
            }
        }
    }, [filingData, exhibit_num]);

    if (!filingData || !filingData.sec_filing) {
        return "";
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex flex-row">

                <div className={`overflow-hidden transition-width duration-300 ease-in-out bg-filing-menu-background ${menuVisible ? 'w-80' : 'w-4 pl-1'}`}>
                    <SECFilingMenu
                        ticker={ticker}
                        type={type}
                        filingData={filingData}
                        menuVisible={menuVisible}
                        toggleMenu={toggleMenu}
                    />
                </div>

                <div className="flex-1 h-full">
                    <SECFilingIFrame
                        filingDoc={filingData ? filingData.exhibits[seqIndex].url :  ""}
                        cik={filingData ? filingData.company.cik : ""}
                        fid={filingData ? filingData.sec_filing.FID : 0}
                        eid={filingData ? filingData.exhibits[seqIndex].eid : 0}
                        filingData={filingData}
                        exhibitNum={exhibit_num}
                        menuVisible={menuVisible}
                        currentUser={currentUser}
                    />

                </div>
            </div>
        </div>
    );
};

export default SECFiling;
