import React, { useState, useEffect, useRef } from "react";
import axiosInstance from '../../api';
import { useNavigate } from "react-router-dom";
import { API_URL, SearchForIndustry } from "../../config";
import {arrayToStringWithCommasAndLengthLimit} from "../../values";

function SearchBar() {
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const navigate = useNavigate();

    const searchContainerRef = useRef();

    const handleClickOutside = (event) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSuggestionClick = (ticker, cik) => {
        setSearchTerm("");
        setSuggestions([]);
        if (ticker) {
            const tickerToUse = Array.isArray(ticker) && ticker.length > 1 ? ticker[0] : ticker;
            navigate(`/company/${tickerToUse}`);
        } else {
            navigate(`/company/${cik}`);
        }

    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, suggestions.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter") {
            if (selectedSuggestionIndex >= 0 && suggestions[selectedSuggestionIndex]?.tickers) {
                const selectedCompany = suggestions[selectedSuggestionIndex];
                console.log("Selected Index:", selectedSuggestionIndex);
                console.log("Selected Company:", selectedCompany);
                handleSuggestionClick(selectedCompany.tickers, selectedCompany.cik);
            } else if (selectedSuggestionIndex >= 0 && suggestions[selectedSuggestionIndex]?.cik) {
                const selectedCompany = suggestions[selectedSuggestionIndex];
                handleSuggestionClick(selectedCompany.tickers, selectedCompany.cik);
            } else if (selectedSuggestionIndex === -1) {
                if (suggestions?.length > 0 && suggestions[0]?.tickers) {
                    const firstCompany = suggestions[0];
                    handleSuggestionClick(firstCompany.tickers, firstCompany.cik);
                }
            }
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                axiosInstance
                    .get(`${API_URL}/api/company/search/${searchTerm}`)
                    .then((response) => {
                        if(SearchForIndustry && response.data.length === 0) {
                            axiosInstance
                                .get(`/facts/industry/search?q=${searchTerm}`)
                                .then((response) => {
                                    setSuggestions(response.data);
                                })
                                .catch((error) => {
                                    console.error("Error fetching suggestions from industry search:", error);
                                });
                        } else {
                            setSuggestions(response.data);
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching suggestions:", error);
                    });
            } else {
                setSuggestions([]);
            }
        }, 0); // debounce time milliseconds

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);


    return (
        <div className="flex items-center bg-gray-200 rounded-sm px-0 h-9 my-0 relative w-full max-w-2xl mx-auto" ref={searchContainerRef}>
            <input
                type="text"
                autoComplete="new-password"
                placeholder="Ticker / Company Name / Industry"
                className="border-none bg-transparent text-gray-600 text-sm px-4 outline-none w-full rounded-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {Array.isArray(suggestions) && suggestions.length > 0 && (
                <ul className="absolute bg-accent-one list-none m-0 p-0 mx-3 shadow-md overflow-auto w-11/12 z-10 rounded-b-md top-full">
                    {suggestions.map((company, index) => (
                        <li
                            key={company.cik}
                            onClick={() => handleSuggestionClick(company.tickers, company.cik)}
                            className={`p-1 mx-2 cursor-pointer border-b border-gray-600 text-gray-300  ${index === selectedSuggestionIndex ? "bg-theme-contrast text-white font-semibold" : ""}`}
                        >
                            <div className="flex justify-between">
                                <span className="flex-1 text-left">{company.name}</span>
                                <div>
                                    <div>
                                        {company.tickers ?
                                <span className="text-right text-gray-300 rounded-sm px-2 p-1 bg-gray-800">
                                    {company.exchange ?
                                        company.exchange + ": "
                                        : ''
                                    }
                                    {arrayToStringWithCommasAndLengthLimit(company.tickers, 28)}
                                </span> : ''
                                        }
                                    </div>
                                    <div>

                                    </div>

                            </div>

                            </div>
                            <div>
                                {
                                    company.is13fFiler ?
                                        <span className="text-right text-gray-400 text-xs rounded-sm">
                                    13F Filer

                                </span>
                                        : ''
                                }
                            </div>

                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default SearchBar;