import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Portfolio from './Components/Portfolio';
import { AuthProvider } from './firebase/AuthContext';
import Home from './Components/Home';
import Company from "./Components/Company";
import SECFiling from "./Components/SECFiling";
import CompanyTableSearch from "./Components/TableSearchPageV2";
import CompanyTableSearchFilings from "./Components/TableSearchPageFilings2";
import About from "./Components/About";
import Landing from "./Components/Landing";
import ContactUs from "./Components/ContactUs";
import Profile from "./Components/Profile/Profile";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Login from "./Components/Login";
import Highlights from "./Components/HighlightsV2";
import RecentFilingsFeed from "./Components/RecentFilingsFeed";
import RecentFilingsFeedIndustryByTicker from "./Components/IndustryGroup";
import NotFoundPage from "./Components/NotFoundPage";
import CompanyInsiderTG from "./Components/CompanyInsidersTG";
import CompanyDocumentSearch from "./Components/CompanyDocumentSearch";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import LandingProducts from "./Components/LandingProducts";
import TranscriptDocument from "./Components/Transcript/Transcript";
import CancelSubscriptionProfile from "./Components/Profile/CancelSubscriptionProfile";
import { QueryClient, QueryClientProvider } from 'react-query';
import CompanyEnhanced from "./Components/Company/CompanyV4";

const queryClient = new QueryClient();

function App() {

  return (
      <QueryClientProvider client={queryClient}>

      <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/subscriptions" element={<LandingProducts />} />
              <Route path="/login" element={<Login />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/profile" element={<Profile />}/>
              <Route path="/profile/actions/unsubscribe" element={<CancelSubscriptionProfile />}/>
              <Route path="/highlights" element={<Highlights />}/>
              <Route path="/recent" element={<RecentFilingsFeed />}/>
              <Route path="/recent/:n" element={<RecentFilingsFeed />}/>
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/industry/filings/company/:ticker" element={<RecentFilingsFeedIndustryByTicker />} />

              <Route path="/company/:ticker" element={<Company />} />
              <Route path="/company-4/:ticker" element={<CompanyEnhanced />} />

              <Route path="/company/insider/:ticker" element={<CompanyInsiderTG />} />
              <Route path="/company/search/:ticker" element={<CompanyDocumentSearch />} />
              <Route path="/tables/:ticker/:table_id" element={<CompanyTableSearch />} />
              <Route path="/filings/tables/:ticker/:table_id" element={<CompanyTableSearchFilings />} />
              <Route path="/company/:ticker/filing/:type/:accession_num" element={<SECFiling />} />
              <Route path="/company/:ticker/transcript/:document_id" element={<TranscriptDocument />} />
              <Route path="/company/:ticker/filing/:type/:accession_num/exhibit/:exhibit_num" element={<SECFiling />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <ToastContainer />
          </div>
        </Router>
      </AuthProvider>
      </ThemeProvider>
      </QueryClientProvider>
  );
}

export default App;