import React, { useEffect, useState } from 'react';
import NavBarSmall from "../NavBarSmall";
import Footer from "../Footer";
import { API_URL } from "../../config";
import axiosInstance from '../../api';
import { useAuth } from "../../firebase/AuthContext";
import { Link } from 'react-router-dom';
import CompanyTickerBubbleField from "../SimilarCompanies/CompanyTickerBubbleField";
import UserSubscriptionDetails from "../UserSubscriptionDetails/UserSubscriptionDetails";

const Profile = () => {
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(null);
    const [showSubscription, setShowSubscription] = useState(false);

    useEffect(() => {
        if (currentUser) {
            document.title = "Settings - " +currentUser.email +" | Modeledge";
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            const headers = currentUser
                ? { Authorization: `Bearer ${currentUser.token}` }
                : {};
            axiosInstance.get(`${API_URL}/api/users/profile`, { headers })
                .then(response => {
                    console.log(response.data)
                    setUserData(response.data)
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [currentUser]);

    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <div className="md:w-1/2 w-4/5 mx-auto my-10">
                <div className="bg-gray-50 md:px-8 px-4 md:py-6 py-3 rounded-md shadow text-gray-800 border-t border-gray-200 md:space-y-4 space-y-3">
                    {userData ? (
                        <div>
                            <h2 className="text-xl font-semibold text-gray-800 mb-4">Account</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {userData.email && (
                                    <div>
                                        <p className="text-sm font-medium text-gray-500">Email</p>
                                        <p className="mt-1 text-md text-gray-900">{userData.email}</p>
                                    </div>
                                )}
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Companies in Portfolio</p>
                                    <p className="mt-1 text-md text-gray-900">{userData.companiesInPortfolio}</p>
                                </div>

                                <div>
                                    <p className="text-sm font-medium text-gray-500">User Since</p>
                                    <p className="mt-1 text-md text-gray-900">{new Date(userData.userSince).toLocaleDateString()}</p>
                                </div>
                            </div>
                            {showSubscription ?
                                <UserSubscriptionDetails currentUser={currentUser} />
                                :
                                <button
                                    onClick={() => setShowSubscription(!showSubscription)}
                                    className="px-3 py-1 mt-2 bg-neutral-500 text-white rounded-sm hover:bg-neutral-600 transition duration-200 ease-in-out"
                                >
                                    Show Subscription Details
                                </button>
                            }

                            <div className="col-span-2 mt-3">
                                <p className="text-sm font-medium text-gray-500">Portfolio Companies</p>
                                <div className="mt-2 flex flex-row flex-wrap p-1 border border-gray-200 rounded-lg">
                                    <CompanyTickerBubbleField companies={userData.companies} />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 mt-8">
                                <Link to="/highlights" className="px-4 py-2 bg-gray-500 text-white rounded border border-gray-600 hover:bg-gray-700 transition duration-200 ease-in-out">My Highlights</Link>
                                <Link to="/portfolio" className="px-4 py-2 bg-gray-500 text-white rounded border border-gray-600 hover:bg-gray-700 transition duration-200 ease-in-out">My Portfolio</Link>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-96">
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;