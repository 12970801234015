import React, {useState, useEffect, useRef} from "react";
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import FilingCardCompanySearch from "../FilingCards/FilingCardCompanySearch";

const TableSearchHints = () => (
    <div className="flex justify-center bg-filing-menu-background">
        <div className="flex-col p-6 rounded-lg bg-gray-50">
            <h4>Don't see your table? Try refining your search</h4>
            <ul className="list-disc text-sm text-gray-500 px-2 py-4 ">
                <li>"Sales" instead of "revenue"</li>
                <li>"Total" when applicable</li>
            </ul>
            <h4>Try company or industry specific terms:</h4>
            <ul className="list-disc text-sm text-gray-500 px-2 py-4 ">
                <li>"ARR", "customers"</li>
                <li>"tons", "reserves"</li>
                <li>"throughput"</li>
            </ul>
        </div>
    </div>
);


function TableSearchDivV2({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [tables, setTables] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const [docSearchTerm, setDocSearchTerm] = useState("");
    const scrollContainerRef = useRef(null);

    const handleTableClick = (id) => {
        navigate(`/tables/${ticker}/${id}`);
    };

    const handleTableFilingClick = (filing) => {
        navigate(`/company/${ticker}/filing/${filing.filing_type}/${filing.accession_number}`);
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (searchTerm) {
                setError(null);
                axiosInstance
                    .get(`${API_URL}/api/tables/search/${ticker}?q=${searchTerm}`)
                    .then((response) => {
                        setTables(response.data);
                    })
                    .catch((error) => {
                        console.error("Error fetching suggestions:", error);
                        setError(error);
                    });
            } else {
                setTables([]);
            }
        }, 225);

        return () => clearTimeout(debounceTimeout);
    }, [searchTerm, ticker]);

    useEffect(() => {
        if (searchTerm === "") {
            setTables([])
        }
    }, [searchTerm]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, tables.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter") {
            if (selectedSuggestionIndex >= 0 && tables[selectedSuggestionIndex]?.id) {
                const selectedTable = tables[selectedSuggestionIndex];
                handleTableClick(selectedTable.id);
            } else if (selectedSuggestionIndex === -1) {
                // do nothing
            }
        }
    };

    const clearSearch = () => {
        setSearchTerm("");
    };

    if (error) {
        return <div>Error fetching data</div>;
    }


    const search = () => {
        // Perform navigation
        navigate(`/company/search/${ticker}?query=${setDocSearchTerm}`);
    }

    const handleKeyDownDocSearch = (e) => {
        if (e.key === "Enter") {
            search();
        }
    };

    // Adjusted to call the search function directly
    const handleDocSearchClick = () => {
        search();
    };

    return (
        <div className="container mx-auto pt-2 px-1 md:px-0 w-full" >
            <div className="flex md:flex-row flex-col justify-start items-center md:space-x-4">
                <div className="flex-1 w-full">
                    <div className="w-full mx-auto">
                        <div className="flex flex-col md:flex-row items-center bg-filing-menu-background rounded text-sm border border-gray-400 py-2 md:h-10 relative mb-2">

                        <input
                            type="text"
                            placeholder="Table search: revenues, customers, cash flow, debt, net income..."
                            className="border-none bg-gray-100 text-gray-600 text-sm p-1 px-2 mx-1 outline-none rounded-sm w-full"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button
                            className="bg-company-button hover:bg-code-light-black text-white text-xs font-bold py-1 px-2 m-2 rounded"
                            onClick={clearSearch}
                        >
                            Clear
                        </button>
                        <input
                            type="text"
                            placeholder="Document search"
                            className="border-none bg-gray-100 text-gray-600 text-sm p-1 px-2 mx-1 outline-none w-full rounded-sm"
                            value={docSearchTerm}
                            onChange={(e) => setDocSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDownDocSearch}
                        />
                        <button
                            className="bg-company-button hover:bg-code-light-black text-white text-xs font-bold py-1 px-2 m-2 rounded"
                            onClick={handleDocSearchClick} // Changed to call handleSearchClick
                        >
                            Search
                        </button>
                    </div>

                    {searchTerm && tables && tables.length > 0 && (
                        <div className="relative w-full overflow-hidden border-neutral-400 border border-1 rounded-md">
                            <div
                                ref={scrollContainerRef}
                                className="flex overflow-x-auto pb-4 pt-2 space-x-2 scrollbar-hide "
                                style={{
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    WebkitOverflowScrolling: 'touch'
                                }}
                            >
                                <div className="flex-shrink-0 bg-filing-menu-background" /> {/* Spacer */}
                                {tables.map((table, index) => (
                                    <div className="bg-filing-menu-background min-w-fit">
                                        <div
                                            key={table.id}
                                            className="flex-shrink-0 border border-gray-300 rounded-md cursor-pointer text-black bg-gray-50 max-w-3xl pb-2"
                                        >
                                            <div
                                                onClick={() => handleTableFilingClick(table.filing)}
                                                className="rounded-md"
                                            >
                                                <FilingCardCompanySearch filing={table.filing} exhibit={table.exhibit} />
                                            </div>
                                            <div onClick={() => handleTableClick(table.id)}>
                                                <div className="flex justify-center md:px-2 break-words" dangerouslySetInnerHTML={{ __html: table.html }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="bg-filing-menu-background min-w-fit max-w-2xl">
                                    <div className="flex-shrink-0 border border-gray-300 rounded px-2 text-black bg-filing-menu-background">
                                        <div className="bg-filing-menu-background py-4">
                                            Table search tips:
                                        </div>
                                        <div className="bg-filing-menu-background">
                                            <TableSearchHints/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 bg-filing-menu-background" />
                            </div>
                        </div>
                    )}
                </div>
                </div>

            </div>
        </div>
    );
}

export default TableSearchDivV2;