import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../../config";

const DescriptionComponent = ({ ticker }) => {
    const [descriptionData, setDescriptionData] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const descriptionRef = useRef(null);


    useEffect(() => {
        const fetchDescription = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/api/company/facts/description/${ticker}`);
                setDescriptionData(response.data);
            } catch (error) {
                console.error('Error fetching description:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isVisible) {
            fetchDescription();
        } else {
            setDescriptionData(null);
        }
    }, [ticker, isVisible]);

    const toggleDescription = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>

            <button
                onClick={toggleDescription}
                className="bg-gray-100 text-gray-700 border border-gray-300 px-2 py-1 rounded-md text-xs font-medium shadow-sm hover:bg-gray-200 transition-colors duration-200"
            >
                {isVisible ? 'Hide Description' : 'Show Description'}
            </button>
            {isVisible && (
                <div className="my-2 p-7 bg-gray-200 rounded-md" ref={descriptionRef}>
                    {isLoading ? (
                        <p className="text-gray-600">Loading...</p>
                    ) : descriptionData ? (
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Description</h3>
                                <p>{descriptionData.description}</p>
                            </div>
                            {descriptionData.formerNames && descriptionData.formerNames.length > 0 && (
                                <div className="text-sm">
                                    <strong className="font-semibold">Former Names:</strong>
                                    <ul className="list-disc pl-5">
                                        {descriptionData.formerNames.map((formerName, index) => (
                                            <li key={index}>
                                                {formerName.name} from {new Date(formerName.from).toLocaleDateString()} to {new Date(formerName.to).toLocaleDateString()}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className="text-gray-600">No description available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default DescriptionComponent;