import React, { useState, useRef, useEffect } from 'react';
import useSSE from '../SSE/sse';
import { API_URL } from "../../config";
import LoginRequiredModal from "../YouMustBeLoggedIn/YouMustBeLoggedIn";
import { useSubscriptionModal } from "../SubscriptionModal/useSubscriptionModal"
import { Button } from "../ui/button";
import { CompanyQueryTips, LLMPhilosophy, LLMWarning } from "../ui/languageModelWarning";
import { SubscriptionModal } from "../SubscriptionModal/SubscriptionModal";
import { useNavigate } from 'react-router-dom';
import CompanyArtifact from "./CompanyArtifact";
import CompanyArtifactsList from "./CompanyArtifactList";

const SummarizeIcon = () => (
    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="currentColor"/>
        <path d="M12 14V13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);


const Tooltip = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (isVisible && triggerRef.current && tooltipRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            setPosition({
                top: triggerRect.bottom + window.scrollY + 10,
                left: triggerRect.left + window.scrollX + (triggerRect.width / 2) - (tooltipRect.width / 2)
            });
        }
    }, [isVisible]);

    return (
        <div className="relative inline-block">
            <div
                ref={triggerRef}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                className="cursor-help"
            >
                {children}
            </div>
            {isVisible && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 max-w-[300px]"
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                    }}
                >
                    {content}
                    <div className="absolute w-2 h-2 bg-gray-900 transform rotate-45 top-[-4px] left-[calc(50%-4px)]" />
                </div>
            )}
        </div>
    );
};

const QueryOptions = ({ setDataSet }) => {
    const options = [
        {
            value: "current_data",
            label: "Default",
            description: "Target data: News filings, transcripts, last annual filing"
        },
        {
            value: "transcript_data",
            label: "Transcript Default",
            description: "Query recent transcript data - may not include full text"
        },
        {
            value: "most_recent_transcript",
            label: "Most Recent Transcript Full Text",
            description: "Best for detailed queries on the latest transcript"
        },
        {
            value: "transcripts_full_text",
            label: "Transcripts Full Text (last 3 only)",
            description: "Queries full text of the last 3 transcripts, potentially weak on haystack-like queries"
        }
    ];

    return (
        <div className="space-y-4">
            {options.map((option) => (
                <div key={option.value} className="flex items-center space-x-3">
                    <input
                        type="radio"
                        id={option.value}
                        name="dataSet"
                        value={option.value}
                        onChange={(e) => setDataSet(e.target.value)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label htmlFor={option.value} className="text-sm font-medium text-gray-900">
                        {option.label}
                    </label>
                    <Tooltip content={option.description}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};


const CompanyQueryInputV1 = ({ subscriptionTier, companyID, currentUser, refreshToken }) => {
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [query, setQuery] = useState('');
    const [queryRequested, setQueryRequested] = useState('');
    const [dataSet, setDataSet] = useState('current_data');
    const { data, error, streamId, isComplete } = useSSE(streamUrl);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [displayData, setDisplayData] = useState('');
    const [localError, setLocalError] = useState(null);
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    const navigate = useNavigate();
    const [showFeedback, setShowFeedback] = useState(false);
    const inputRef = useRef(null);
    const responseRef = useRef(null);
    const [currentArtifactId, setCurrentArtifactId] = useState(null);

    const handleDeleteArtifact = (deletedStreamId) => {
        console.log('Artifact deleted:', deletedStreamId);
        if (currentArtifactId === deletedStreamId) {
            setIsStreaming(false);
            setDisplayData('');
            setShowFeedback(false);
            setQueryRequested('');
            setQuery('');
            setCurrentArtifactId(null);
        }
        // Trigger a refresh of the CompanyArtifactsList
        if (companyArtifactsListRef.current) {
            companyArtifactsListRef.current.refreshArtifacts();
        }
    };

    const handleSummarize = () => {
        if (!currentUser?.token) {
            setIsLoginModalOpen(true);
            return;
        }
        setDisplayData('');
        setLocalError(null);
        setIsStreaming(true);
        setShowFeedback(false);
        setQueryRequested(query);

        let dataId = 'current_data';
        if (dataSet === 'transcript_data') {
            dataId = 'tc';
        } else if (dataSet === 'transcripts_full_text') {
            dataId = 'tft';
        } else if (dataSet === 'current_data') {
            dataId = 'cd';
        } else if (dataSet === 'most_recent_transcript') {
            dataId = 'mrt';
        }

        setStreamUrl(`${API_URL}/api/company/query/llm/${companyID}?q=${query}&auth=${currentUser.token}&d=${dataId}`);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const companyArtifactsListRef = useRef(null);

    useEffect(() => {
        if (data && streamId) {
            setDisplayData(data);
            setIsStreaming(false);
            setCurrentArtifactId(streamId);
        }
    }, [data, streamId]);

    useEffect(() => {
        if (streamId) {
            console.log("stream_id: " + streamId)
        }
    }, [streamId]);

    useEffect(() => {
        if (isComplete && !isStreaming) {
            setShowFeedback(true)
        }
    }, [isComplete, isStreaming])

    // New effect for scrolling the page to the bottom
    useEffect(() => {
        if (responseRef.current) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [displayData]);

    useEffect(() => {
        if (localError) {
            setIsStreaming(false);
            switch(localError.type) {
                case 'forbidden':
                    handleSubscriptionModal();
                    break;
                case 'unauthorized':
                    setIsLoginModalOpen(true);
                    break;
                case 'connection':
                case 'unknown':
                default:
                    setDisplayData('');
                    break;
            }
        }
    }, [localError, handleSubscriptionModal]);


    useEffect(() => {
        if (error) {
            console.log('Component received error:', error);
            setLocalError(error);
            setIsStreaming(false);  // Enable the button if there's an error

            if (error.message === "Unauthorized") {
                console.log("User: " + currentUser.token);
                refreshToken();
            }
        }
    }, [error, currentUser, refreshToken]);

    const isSubscriptionFree = subscriptionTier === 'free';


    const handleInputChange = (e) => {
        setQuery(e.target.value);
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    };

    const getCurrentFormattedDate = () => {
        const now = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };

        try {
            console.log("returning en-us")
            return now.toLocaleString('en-US', options);
        } catch (e) {
            console.log("custom")
            return `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()} - ${now.getHours()}:${now.getMinutes().toString().padStart(2, '0')}`;
        }
    };

    return (
        <div className="min-h-screen relative font-sans lg:w-3/4 mx-auto border-neutral-300 border-r border-l p-4 pb-4 bg-gray-200 px-6">
            <h2 className="text-md font-bold mb-2 ml-1 mt-4">Query Company Data With AI Language Model</h2>

            <div className={`${isSubscriptionFree ? 'opacity-70 pointer-events-none' : ''}`}>
                <div className="px-2">
                    <div className="pb-2"><LLMWarning /></div>
                    <div className="pb-2"><LLMPhilosophy /></div>
                    <div className="mb-2"><CompanyQueryTips /></div>
                    <div className="my-4">
                        <QueryOptions setDataSet={setDataSet}/>
                    </div>
                </div>

                <div className="mb-4 mx-2">
                    <div className="flex space-x-2 ">
                        <div className="flex-grow relative">
                            <textarea
                                ref={inputRef}
                                placeholder="Query recent company documents..."
                                value={query}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
                                style={{ height: '40px' }}

                            />
                        </div>
                        <Button
                            onClick={handleSummarize}
                            disabled={isStreaming || !query.trim()}
                            className={`flex items-center justify-center text-white rounded-md transition-colors duration-300 ${
                                isStreaming || !query.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                            }`}
                            style={{ height: '40px' }}
                        >
                            <SummarizeIcon />
                            <span className="ml-2">Query</span>
                        </Button>
                    </div>
                </div>

                <div className={"mx-2"}>
                    {(displayData || isStreaming) ? (
                        <CompanyArtifact
                            currentUser={currentUser}
                            queryRequested={queryRequested}
                            streamId={currentArtifactId}
                            responseRef={responseRef}
                            isStreaming={isStreaming}
                            displayData={displayData}
                            showFeedback={showFeedback}
                            createdDate={getCurrentFormattedDate()}
                            onDelete={handleDeleteArtifact}
                        />
                    ) : (
                        <CompanyArtifactsList
                            ref={companyArtifactsListRef}
                            currentUser={currentUser}
                            companyID={companyID}
                            onDelete={handleDeleteArtifact}
                        />
                    )}
                </div>

                {localError && (
                    <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-md">
                        <p>Error Type: {localError.type}</p>
                        <p>Error Message: {localError.message}</p>
                    </div>
                )}

                <div className="mt-4">
                    <LLMWarning />
                </div>
            </div>

            {isSubscriptionFree && (
                <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center z-10">
                    <div className="text-center">
                        <span className="text-gray-600 font-semibold block mb-4">
                            Upgrade your subscription to access this feature
                        </span>
                        <Button
                            onClick={() => navigate('/subscriptions')}
                            className="py-2 px-4 bg-blue-600 text-white text-base font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                        >
                            Subscription Options
                        </Button>
                    </div>
                </div>
            )}

            <SubscriptionModal
                isOpen={showSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                subscriptionRequired={"Basic"}
                pageMessage={"AI Language Model queries available under our Basic subscription."}
            />
            <LoginRequiredModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
        </div>
    );
};

export { CompanyQueryInputV1 };